import { ReactComponent as VerticalDivider } from '../../assets/images/vertical-decorative-divider.svg'
import { ReactComponent as HorizontalDivider } from '../../assets/images/horizontal-decorative-divider.svg'
import useBreakpoint from '../../utility/hooks/useBreakPoint'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

const SpecialMenuDivider = ({ leftListLength, rightListLength, rowIndex, svgAverageHeight, fill, opacity, marginBottom, marginLeftRight }) => {
  const menuRowsHeight = useSelector(state => state.menuRowsHeight.menuRowsHeight)
  const breakpoint = useBreakpoint()

  const buildVerticalDivider = () => {
    let height
    let numberOfDividers
    const leftThreshold = leftListLength >= 6
    const rightThreshold = rightListLength >= 6

    switch (leftThreshold || rightThreshold) {
      case true:
        numberOfDividers = Math.round(menuRowsHeight[rowIndex] / svgAverageHeight)
        height = menuRowsHeight[rowIndex] / numberOfDividers
        return (
          <div style={{ display: 'flex', flexDirection: 'column', margin: `0 ${marginLeftRight}` }}>
            {Array.from({ length: numberOfDividers }, (_, i) => (
              <VerticalDivider key={i} style={{ height }} fill={fill} opacity={opacity} />
            ))}
          </div>
        )
      default:
        return <VerticalDivider style={{ height: menuRowsHeight[rowIndex], margin: `0 ${marginLeftRight}` }} fill={fill} opacity={opacity} />
    }
  }

  switch (breakpoint) {
    case 'xl':
      return menuRowsHeight.length > 0 && buildVerticalDivider()
    case 'lg':
      return menuRowsHeight.length > 0 && buildVerticalDivider()
    case 'md':
      return <HorizontalDivider style={{ height: '100%', width: '100%', marginBottom }} fill={fill} opacity={opacity} />
    case 'sm':
      return <HorizontalDivider style={{ height: '100%', width: '100%', marginBottom }} fill={fill} opacity={opacity} />
    case 'xs':
      return <HorizontalDivider style={{ height: '100%', width: '100%', marginBottom }} fill={fill} opacity={opacity} />
  }
}

SpecialMenuDivider.propTypes = {
  leftListLength: PropTypes.number.isRequired,
  rightListLength: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  svgAverageHeight: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  marginBottom: PropTypes.number.isRequired,
  marginLeftRight: PropTypes.string.isRequired,
}

export default SpecialMenuDivider
