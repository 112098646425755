import spoon from '../assets/images/spoon.svg'
import bgMin from '../assets/images/bg-min.jpg'
import bgMinPlaceholder from '../assets/images/bg-min-placeholder.jpg'
import photo1 from '../assets/images/photo-1.jpg'
import photo1placeholder from '../assets/images/photo-1-placeholder.jpg'
import photo2placeholder from '../assets/images/photo-2-placeholder.jpg'
import photo3placeholder from '../assets/images/photo-3-placeholder.jpg'
import photo2 from '../assets/images/photo-2.jpg'
import photo3 from '../assets/images/photo-3.jpg'

const carouselPhotos = [
  {
    fullImage: photo1,
    placeholder: photo1placeholder,
  },
  {
    fullImage: photo2,
    placeholder: photo2placeholder,
  },
  {
    fullImage: photo3,
    placeholder: photo3placeholder,
  },
]

export default {
  spoon,
  bgMin,
  bgMinPlaceholder,
  carouselPhotos,
}
