import React from 'react'

import { AboutUs, Header, SpecialMenu } from './container'
import { Navbar } from './components'
import { Spinner } from 'react-redux-spinner'
import './App.css'

const App = () => {
  return (
    <>
      <Navbar />
      <Header />
      <AboutUs />
      <SpecialMenu />
      <Spinner config={{ easing: 'ease' }} />
    </>
  )
}

export default App
