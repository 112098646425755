/* Redux Imports */
import { combineReducers } from 'redux'

/* Reducer Imports */
import menuRowsHeight from '../reducers/menuRowsHeight/menuRowsHeight'
import menuData from '../reducers/menuData/menuData'

/* Pending tasks reducer */
import { pendingTasksReducer } from 'react-redux-spinner'

const rootReducer = combineReducers({
  menuData,
  menuRowsHeight,
  pendingTasks: pendingTasksReducer,
})

export default rootReducer
