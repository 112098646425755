import React from 'react'

import AnimationPlayer from '../../components/AnimationPlayer/AnimationPlayer'
import lantern from '../../assets/lottie/red-lantern.json'
import { Parallax } from 'react-parallax'
import { images } from '../../constants'
import './AboutUs.css'

const AboutUs = () => {
  return (
    <Parallax
      bgImage={images.bgMin}
      bgImageAlt=''
      strength={500}
      renderLayer={percentage => (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, ${percentage - 0.25}))`,
            width: '100%',
            height: percentage * 150,
            zIndex: 1,
          }}
        />
      )}
    >
      <div className='app__aboutus flex__center section__padding' id='about'>
        <div className='app__aboutus-content flex__center'>
          <div className='app__aboutus-content_about'>
            <h1 className='headtext__cormorant' style={{ color: 'var(--color-crimson)' }}>
              Kina Grill
            </h1>
            <img src={images.spoon} alt='about_spoon' className='spoon__img' />
            <h1 className='app__aboutus-content_txt'>Hestedamsgade 14, 8700 Horsens</h1>
            <h1 className='app__aboutus-content_txt'>Åbningstider:</h1>
            <p className='p__opensans'>Mandag:</p>
            <p className='p__opensans'>Lukket</p>
            <p className='p__opensans'>Tirsdag - Søndag:</p>
            <p className='p__opensans'>16:00 - 21:00</p>
          </div>

          <div className='app__aboutus-content_lantern flex__center'>
            <AnimationPlayer animation={lantern} width={200} height={200} />
          </div>

          <div className='app__aboutus-content_history'>
            <h1 className='app__aboutus-content_txt'>Nyd maden her eller tag den med hjem</h1>
            <img src={images.spoon} alt='about_spoon' className='spoon__img' />
            <p className='p__opensans'>Køkkenet tilbyder specielle selskabsmenuer efter aftale</p>
          </div>
        </div>
      </div>
    </Parallax>
  )
}

export default AboutUs
