import React from 'react'
import './Navbar.css'

const Navbar = () => {
  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <div className='app__navbar-logo-txt'>KINAGRILL</div>
      </div>
      <div className='app__navbar-login'>
        <div />
        <a className='p__opensans' style={{ fontWeight: 'bold' }}>
          Tlf: 75 61 43 35
        </a>
      </div>
    </nav>
  )
}

export default Navbar
