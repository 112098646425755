import React from 'react'
import ReactDOM from 'react-dom/client'
import registerServiceWorker from './registerServiceWorker'
import App from './App'
import './index.css'

// Redux Imports
import { Provider } from 'react-redux'
import { store } from './redux/storeConfig/store'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

registerServiceWorker()
