import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import useInViewport from '../../utility/hooks/useInViewport'
import { MenuItem } from '../../components'
import FadeIn from 'react-fade-in'

const SpecialMenuObserver = column => {
  const containerRef = useRef()
  const inViewPort = useInViewport(containerRef)
  return (
    <FadeIn delay={500} transitionDuration={800} visible={inViewPort}>
      <div ref={containerRef}>
        {column?.column?.dishes?.map((dish, i) => (
          <MenuItem
            key={i}
            number={dish.number}
            label={dish.label}
            title={dish.title}
            price={dish.price}
            description={dish.description}
            dishList={dish.dishList}
          />
        ))}
      </div>
    </FadeIn>
  )
}

SpecialMenuObserver.propTypes = {
  column: PropTypes.shape({}),
}

export default SpecialMenuObserver
