/* Redux, Thunk & Root Reducer Imports */
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import { configureStore } from '@reduxjs/toolkit'

/* Own */
import rootReducer from '../reducers/rootReducer'

/* Init middleware */
const middleware = [thunk, createDebounce()]

/* Create store */
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production',
})

export { store }
