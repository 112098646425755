import React from 'react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import ReactImageAppear from 'react-image-appear'
import { images } from '../../constants'
import './Header.css'

const Header = () => {
  return (
    <div style={{ height: '100%' }}>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        swipeScrollTolerance={15}
        preventMovementUntilSwipeScrollTolerance={true}
      >
        {images.carouselPhotos.map((img, index) => (
          <ReactImageAppear
            key={index}
            src={img.fullImage}
            placeholder={img.placeholder}
            animation='blurIn'
            animationDuration='1s'
            easing='ease-in'
            showLoader={false}
          />
        ))}
      </Carousel>
    </div>
  )
}

export default Header
