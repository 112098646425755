import React, { createRef, useEffect, useMemo } from 'react'

import AnimationPlayer from '../../components/AnimationPlayer/AnimationPlayer'
import SpecialMenuDivider from './SpecialMenuDivider'
import SpecialMenuObserver from './SpecialMenuObserver'

import { setMenuRowsHeight } from '../../redux/actions/menuRowsHeight/menuRowsHeight'
import { fetchMenuData } from '../../redux/reducers/menuData/menuData'
import { batch, useDispatch, useSelector } from 'react-redux'
import { begin, end, pendingTask } from 'react-redux-spinner'

import { images } from '../../constants'
import lantern from '../../assets/lottie/red-lantern.json'
import './SpecialMenu.css'

const SpecialMenu = () => {
  const dispatch = useDispatch()
  const menuData = useSelector(state => state.menuData.menuData)

  useEffect(() => {
    dispatch(fetchMenuData())
  }, [])

  const menuCategoryRows = menuData.reduce((acc, el) => {
    if (!acc.length || acc[acc.length - 1].length === 2) {
      acc.push([])
    }
    acc[acc.length - 1].push(el)
    return acc
  }, [])

  const refsById = useMemo(() => {
    const refs = {}
    menuCategoryRows.forEach((item, index) => {
      refs[index] = createRef()
    })
    return refs
  }, [menuData])

  useEffect(() => {
    const arr = []
    menuCategoryRows.forEach((item, index) => {
      arr[index] = refsById[index]?.current?.clientHeight
    })
    batch(() => {
      dispatch({ type: 'LOADING', [pendingTask]: begin })
      dispatch(setMenuRowsHeight(arr))
      dispatch({ type: 'LOADING_END', [pendingTask]: end })
    })
  }, [refsById])

  const buildMenu = () => {
    return menuCategoryRows.map((column, index) => {
      return (
        <React.Fragment key={index}>
          <div className='app__specialMenu-menu' ref={refsById[index]}>
            <div className='app__specialMenu-menu_items  flex__center'>
              <p className='app__specialMenu-menu_heading'>{column[0].categoryName}</p>
              {column[0].categoryDescription && (
                <p className='p__opensans' style={{ fontWeight: 'bold' }}>
                  {column[0].categoryDescription}
                </p>
              )}
              {column[0].categoryDescriptionList &&
                column[0].categoryDescriptionList.map((item, index) => (
                  <p key={index} className='p__opensans'>
                    {item.description}
                  </p>
                ))}
              <div className='app__specialMenu_menu_items'>
                <SpecialMenuObserver column={column[0]} />
              </div>
            </div>
            {
              <SpecialMenuDivider
                leftListLength={column[0]?.dishes?.length || 0}
                rightListLength={column[1]?.dishes?.length || 0}
                rowIndex={index}
                svgAverageHeight={570}
                fill={'#545454'}
                opacity={0.25}
                marginBottom={25}
                marginLeftRight={'3rem'}
              />
            }
            <div className='app__specialMenu-menu_items  flex__center'>
              <p className='app__specialMenu-menu_heading'>{column[1] ? column[1].categoryName : null}</p>
              {column[1]?.categoryDescription && (
                <p className='p__opensans' style={{ fontWeight: 'bold' }}>
                  {column[1].categoryDescription}
                </p>
              )}
              {column[1]?.categoryDescriptionList &&
                column[1].categoryDescriptionList.map((item, index) => (
                  <p key={index} className='p__opensans'>
                    {item.description}
                  </p>
                ))}
              <div className='app__specialMenu_menu_items'>
                <SpecialMenuObserver column={column[1]} />
              </div>
            </div>
          </div>
          <AnimationPlayer animation={lantern} width={200} height={200} />
        </React.Fragment>
      )
    })
  }

  return (
    <div className='app__specialMenu flex__center section__padding' id='menu'>
      <div className='app__specialMenu-title'>
        <h1 className='headtext__cormorant'>Menu</h1>
        <img src={images.spoon} alt='about_spoon' className='spoon__img' />
      </div>
      {buildMenu()}
    </div>
  )
}

export default SpecialMenu
