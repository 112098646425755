import React from 'react'

import PropTypes from 'prop-types'
import './MenuItem.css'

const MenuItem = ({ number, label, title, price, description, dishList }) => {
  if (number === null) {
    number = ''
  }
  return (
    <div className='app__menuitem'>
      <div className={'app__menuitem-bg'} />
      <div className='app__menuitem-number'>
        {number + label}
        {number === '' && label === '' && '-'}
      </div>
      {title && <div className='app__menuitem-name'>{title}</div>}
      {dishList && (
        <>
          {dishList.map((dish, index) => (
            <div key={index} className='app__menuitem-name'>
              {dish.title}
              <div className='app__menuitem-dash' />
            </div>
          ))}
        </>
      )}
      <div className='app__menuitem-price'>{`${price} Kr.`}</div>
      {description && <div className='app__menuitem-sub'>{description}</div>}
    </div>
  )
}

MenuItem.propTypes = {
  number: PropTypes.number,
  label: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  dishList: PropTypes.arrayOf(PropTypes.shape({})),
}

export default MenuItem
