import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

/* fetch menu data from the api */
export const fetchMenuData = createAsyncThunk('menuData/fetchMenu', async () => {
  const response = await fetch('https://data.mongodb-api.com/app/kina-grill-ewudb/endpoint/menu', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  return response.json()
})

const initialState = {
  loading: false,
  menuData: [],
  error: '',
}

const menuDataSlice = createSlice({
  name: 'menuData',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMenuData.pending, state => {
      state.loading = true
    })
    builder.addCase(fetchMenuData.fulfilled, (state, action) => {
      state.loading = false
      state.menuData = action.payload
      state.error = ''
    })
    builder.addCase(fetchMenuData.rejected, (state, action) => {
      state.loading = false
      state.menuData = []
      state.error = action.error.message
    })
  },
})

export default menuDataSlice.reducer
