import React from 'react'

import Lottie from 'react-lottie-player'
import PropTypes from 'prop-types'

const AnimationPlayer = ({ animation, width, height }) => {
  return <Lottie loop animationData={animation} play style={{ width, height }} />
}

AnimationPlayer.propTypes = {
  animation: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default AnimationPlayer
